<template>
  <form @submit.prevent="submit">
    <div class="row">
      <loading-notification :show="loading" />
    </div>
    <div class="row">
      <div class="flex xs12 md4">
        <va-select
          v-model="selectedName"
          :label="$t('layout.form.nameInput')"
          :options="namesList"
          :no-options-text="$t('layout.empty')"
          :disabled="loading"
          text-by="name"
          searchable
        />
      </div>
      <div class="flex xs12 md4">
        <va-input
          color="info"
          v-model="form.value.value"
          :disabled="loading"
          :label="$t('layout.form.valueInput')"
          :error="!!form.value.errors.length"
          :error-messages="translatedErrors(form.value.errors)"
          @blur="validate('value')"
        />
      </div>
      <div class="flex xs12 md4">
        <va-checkbox
          :label="$t('layout.form.localParameterInput')"
          :disabled="loading || onlyLocalParameters"
          v-model="isLocal"
        />
      </div>
    </div>

    <location-bar
      v-show="isLocal"
      flex-size="md12"
      :loading="loading"
      :with-filters="locationFilters"
      :initial-region="initialRegion"
      @selected-region="adjustRegion"
    />

    <div class="row">
      <div class="flex xs12">
        <va-button
          color="primary"
          :disabled="loading"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { validatorMixin } from '@/services/validator'
const LocationBar = () => import(/* webpackPrefetch: true */ '@/components/extras/Bars/Location')

export default {
  name: 'parameters-form',
  components: {
    LocationBar,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    parameter: {
      type: Object,
      required: false,
      default: function () {
        return {
          id: 0,
        }
      },
    },
  },
  mixins: [validatorMixin],
  data () {
    return {
      isLocal: false,
      selectedName: '',
      locationFilters: ['regions'],
      initialRegion: null,
      selectedRegion: '',
      form: {
        value: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentLocale']),
    isNew () {
      return this.parameter.id === 0
    },
    namesList () {
      const names = [
        {
          id: 1,
          name: this.$t('parameters.names.assistance'),
          value: 'parameters.names.assistance',
        },
        {
          id: 2,
          name: this.$t('parameters.names.decisions'),
          value: 'parameters.names.decisions',
        },
        {
          id: 3,
          name: this.$t('parameters.names.discipleship'),
          value: 'parameters.names.discipleship',
        },
        {
          id: 4,
          name: this.$t('parameters.names.send_trainings'),
          value: 'parameters.names.send_trainings',
        },
        {
          id: 5,
          name: this.$t('parameters.names.information_email'),
          value: 'parameters.names.information_email',
        },
        {
          id: 6,
          name: this.$t('parameters.names.min_presentations'),
          value: 'parameters.names.min_presentations',
        },
        {
          id: 7,
          name: this.$t('parameters.names.max_preaching_points'),
          value: 'parameters.names.max_preaching_points',
        },
      ]

      return names
    },
    onlyLocalParameters () {
      return !this.currentUser.super
    },
  },
  watch: {
    parameter (val) {
      this.setParameter(val)
    },
    currentLocale (val) {
      this.validateAll()
    },
  },
  created () {
    this.initialData()
    this.$nextTick(() => {
      this.validateAll()
      if (!this.currentUser.super) {
        this.isLocal = true
      }
    })
  },
  methods: {
    async setParameter (val) {
      this.setFormData(val)
      if (val.name) {
        this.selectedName = this.namesList.find(n => n.value === val.name)
      }

      if (val.region) {
        this.isLocal = true
        if (!this.selectedRegion) {
          this.selectedRegion = val.region
        }
        if (!this.initialRegion) {
          this.initialRegion = val.region
        }
      }

      if (val.id) {
        await this.$nextTick()
        this.validateAll()
      }
    },
    initialData () {
      if (this.parameter) {
        this.setParameter(this.parameter)
      }
    },
    adjustRegion (region) {
      this.selectedRegion = region
    },
    async submit () {
      await this.validateAll()
      if (!this.formReady) return

      const parameter = this.getFormData(this.parameter)
      if (this.selectedName) {
        parameter.name = this.selectedName.value
      }
      if (this.isLocal && this.selectedRegion) {
        parameter.region_id = this.selectedRegion.id
      } else {
        parameter.region_id = null
      }

      delete parameter.region
      this.$emit('submit', parameter)
    },
  },
}
</script>
